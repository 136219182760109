import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { IDetailModel } from "../models/detail.model";

@Injectable({
    providedIn: "root"
}) export class DetailChangeEventListener{

    private stepsBehave = new BehaviorSubject<number>(1);
    public actualStep = this.stepsBehave.asObservable();

    setTopBarSteps = (step: number) => this.stepsBehave.next(step); 
}