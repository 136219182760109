import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CourseWidget } from "../models/course.model";

@Injectable(
    {
        providedIn:"root"
    }
) export class CourseWidgetService{
    private courseBehave = new BehaviorSubject<CourseWidget>(null);
    public courseUpdated = this.courseBehave.asObservable();

    public SetCourse(obj: CourseWidget){
        obj.amount = (1/obj.cryptoAmount)*obj.amount;
        obj.cryptoAmount = 1;
        this.courseBehave.next(obj);
    }
}