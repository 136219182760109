import { Component, Inject, inject, OnInit } from '@angular/core';
import { AppService, IAppServiceModel } from '../../services/component.service';
import {DOCUMENT} from '@angular/common';


@Component({
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    
    constructor(private _app: AppService, @Inject(DOCUMENT) private _doc) {}

    ngOnInit(): void {
        this._app.addTitle = {
            hideH1: false,
            pageMetaTitle: this._doc.location.hostname,
            pageModel: this._doc.location.hostname
        } as IAppServiceModel;
    }
}
