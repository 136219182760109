import { IMenuItem } from "./menuItem.model";

export const Cultures: IMenuItem[] = [
    {
      "TwoLetters": "cs",
      "Abbreviation": "cs-CZ",
      "CountryName": "Czech"
    },
    {
      "TwoLetters": "en",
      "Abbreviation": "en-GB",
      "CountryName": "English"
    },
    {
      "TwoLetters": "sk",
      "Abbreviation": "sk-SK",
      "CountryName": "Slovak"
    },
    {
      "TwoLetters": "hu",
      "Abbreviation": "hu-HU",
      "CountryName": "Hungarian"
    },
    {
      "TwoLetters": "de",
      "Abbreviation": "de-DE",
      "CountryName": "Germany"
    }
  ];