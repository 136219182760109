<mat-toolbar color="transparent">
  <!-- <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
    <mat-icon>menu</mat-icon>
  </button> -->
  <mat-toolbar-row>
    <a href="/" class="no-decoration">
      <h1 [ngClass]="{'text-transparent': _pageData?.hideH1}">
        {{_pageData?.pageModel}}
      </h1>
    </a>
    <span class="spacer"></span>
    <button mat-icon-button changeClass="showCamera">
      <mat-icon></mat-icon>
    </button>
    <!--
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>menu_book</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <ng-container *ngIf="menuItems !== null">
      <div *ngFor="let item of menuItems">
        <button mat-menu-item (click)="chosen(item.Abbreviation)">
          <span [class]="{'active': item.Abbreviation === _localization}">
            {{item.CountryName}} <span class="flag-icon flag-icon-{{item.TwoLetters}} flag-icon-squared"></span>
          </span>
        </button>
      </div>
    </ng-container>
  </mat-menu> -->
  </mat-toolbar-row>
</mat-toolbar>