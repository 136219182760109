import { Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit, Optional } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { AppService, IAppServiceModel } from '../../services/component.service';

@Component({
    selector: 'navigation-bar',
    templateUrl: './navigation-bar.component.html',
    styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit {

    @Optional()
    public _pageData: IAppServiceModel | null = null;
    @Input() showCamera: boolean = false;

    constructor(
        @Inject(LOCALE_ID) public activeLocale:string,
        private _app: AppService,
        private _meta: Meta,
        private _title: Title
        ) {
        }

    ngOnInit(): void {
        this._app.getTitle.subscribe((next) => {
            if(next !== null){
                this._pageData = next;
                this._title.setTitle(next.pageMetaTitle);
                this._meta.addTags([  
                    { name: 'keywords', content: 'Platba kryptoměnami, zaplatkryptem, cryptopay' },  
                    { name: 'robots', content: 'index, follow' },  
                    { name: 'writer', content: 'HD Crypto. s.r.o' },  
                    { charset: 'UTF-8' }  
                  ]);
            }
        },(err) => console.error(err));
    }
}
