import { Component, OnInit } from '@angular/core';
import { AppService, IAppServiceModel } from '../../services/component.service';

@Component({
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
    constructor(private _app: AppService) {
        this._app.addTitle = {
            hideH1: true,
            pageMetaTitle: $localize`Administrace`,
            pageModel: $localize`Administrace`
        } as IAppServiceModel;
     }

    ngOnInit(): void { }
}
