import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ProgressModel } from "../models/progress.model";

@Injectable({
    providedIn: "root"
})
export class CounterService {
    // This is total number of milliseconds for new Date type created 
    private _timeValue = new Date(0, 0, 0, 0, 0, 0, 0);
    // This is default date type to use as start date
    private _defaultdate = new Date(0, 0, 0, 0, 30, 0, 0);
    private _interfalId;
    private getTimeBeh = new BehaviorSubject<ProgressModel>({
        show: false,
        percent: 0,
        time: new Date(0, 0, 0, 0, 30, 0, 0),
        storno: false
    } as ProgressModel);

    /**
     * Returns filled model and parameters used for progress bar
     * @returns ProgressModel
     */
    private GetModel = (): ProgressModel => {
        let timeOne = Math.abs(this._timeValue.getTime()) - Math.abs(this._defaultdate.getTime());
        let timeTwo = Math.abs(this._timeValue.getTime()) - Math.abs(this.getTimeBeh.getValue().time.getTime());

        let p = (( timeTwo / timeOne ) * 100);
        let s = (p === 0) ? true : false;

        //console.log(p);

        return {
            show: true,
            percent: p,
            storno: s,
            time: this.getTimeBeh.getValue().time
        } as ProgressModel;
    }

    /**
     * Starts timer
     */
    public Start(): void {
        this._interfalId = setInterval(() => {
            let d = new Date(this.getTimeBeh.getValue().time.setMilliseconds(-1000));
            if (d.getHours() == 0 && d.getMinutes() == 0 && d.getSeconds() == 0 && d.getMilliseconds() == 0) {
                this.getTimeBeh.next(this.GetModel());
                this.Stop();
            } else this.getTimeBeh.next(this.GetModel());
        }, 1000);
    }

    /**
     * Stops timer
     */
    public Stop = () => clearInterval(this._interfalId);

    /**
     * Refreshs timer to predefined date and time
     */
    public Refresh = () => {
        this.Stop();
        this.getTimeBeh.next({
            show: false,
            percent: 0,
            time: new Date(0, 0, 0, 0, 30, 0, 0),
            storno: false
        } as ProgressModel);
    }

    /**
     * Subscribe to timer
     */
    public WatchTime = this.getTimeBeh.asObservable();

}