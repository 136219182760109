<ng-container *ngIf="detail !== null">
    <ng-container *ngIf="detail.isMerchant !== true; else merchant">
        <section id="detail">
            <div class="row align-items-center">
                <div class="col-12 col-md-6 text-center text-md-start">
                    <h2 class="m-0" i18n>Převod na účet</h2>
                </div>
            </div>
            <hr class="w-100" />
            <div [ngSwitch]="detail.txState" class="row">
    
                <div *ngSwitchCase="77" class="txState col-12">
                    <div class="state text-center">
                        <mat-icon color="warn" class="text-44">cancel</mat-icon>
                        <p class="flex fa-bold blue text-18 mt-4"><b i18n>Vaší transakci jsme nemohli dokončit z důvodu
                                možného nedodržení našich podmínek.</b></p>
                        <p class="text-14 aab"><span i18n>Kontaktujte nás na</span><a
                                href="mailto:support@hdcrypto.cz">support&#64;hdcrypto.cz</a></p>
                    </div>
                </div>
    
                <div *ngSwitchCase="8" class="txState col-12">
                    <div class="state text-center">
                        <i class="fa fa-loading"></i>
                        <p class="flex fa-bold blue text-18 mt-4"><b i18n>Čekáme na potvrzení sítě</b></p>
                        <p class="text-14 aab"><span i18n>Trvá to jen chvíli v závislosti na vytížení sítě.</span>
                        </p>
                    </div>
                </div>
    
                <div *ngSwitchCase="1" class="txState col-12">
                    <div class="state text-center">
                        <mat-icon color="warn" class="text-44">cancel</mat-icon>
                        <p class="flex fa-bold blue text-18 mt-4"><b i18n>Zrušeno</b></p>
                        <p class="text-14 aab" i18n>Vypršel čas, po který vám garantujeme kurz.</p>
                    </div>
                </div>
                <div *ngSwitchCase="0" class="txState col-12">
                    <div class="state text-center">
                        <mat-icon color="warn" class="text-44">cancel</mat-icon>
                        <p class="flex fa-bold blue text-18 mt-4"><b i18n>Zrušeno</b></p>
                        <p class="text-14 aab" i18n>Vypršel čas, po který vám garantujeme kurz.</p>
                    </div>
                </div>
    
                <div *ngSwitchCase="2" class="txState col-12">
                    <div class="state text-center">
                        <i class="fa fa-loading"></i>
                        <p class="text-success text-18"><b i18n>Čekáme na potvrzení sítě</b></p>
                        <p class="text-14 aab" i18n>Trvá to jen chvíli v závislosti na vytížení sítě.</p>
                    </div>
                </div>
    
                <div *ngSwitchCase="3" class="txState col-12">
                    <div class="state text-center">
                        <i class="fa fa-loading"></i>
                        <p class="text-success text-18"><b i18n>Čekáme na potvrzení sítě</b></p>
                        <p class="text-14 aab" i18n>Trvá to jen chvíli v závislosti na vytížení sítě.</p>
                    </div>
                </div>
    
                <div *ngSwitchCase="4" class="txState col-12">
                    <div class="state text-center">
                        <i class="fa fa-loading"></i>
                        <p class="text-success text-18"><b i18n>Připravujeme platbu</b></p>
                        <p class="text-14 aab" i18n>Za chvilku vám odešleme smluvenou částku na účet.</p>
                    </div>
                </div>
    
                <div *ngSwitchCase="5" class="txState col-12">
                    <div class="state text-center">
                        <i class="fa fa-loading"></i>
                        <p class="text-success text-18"><b i18n>Připravujeme platbu</b></p>
                        <p class="text-14 aab" i18n>Za chvilku vám odešleme slíbenou částku na účet.</p>
                    </div>
                </div>
    
                <div *ngSwitchCase="6" class="txState col-12">
                    <div class="state text-center">
                        <mat-icon class="success-icon">done</mat-icon>
                        <p class="text-success text-18"><b i18n>Zaplaceno na účet</b></p>
                        <p class="text-14 aab" i18n>Děkujeme za využití našich služeb.</p>
                    </div>
                </div>

                <div *ngSwitchCase="7" class="txState col-12">
                    <div class="state text-center">
                        <i class="fa fa-loading"></i>
                        <p class="text-success text-18"><b i18n>Připravujeme platbu</b></p>
                        <p class="text-14 aab" i18n>Za chvilku vám odešleme slíbenou částku na účet.</p>
                    </div>
                </div>
    
                <div *ngSwitchCase="99" class="txState col-12">
                    <div class="state text-center">
                        <mat-icon color="accent" class="text-44">sync_problem</mat-icon>
                        <p class="text-warn text-18"><b i18n>Storno</b></p>
                        <p class="text-14 aab" i18n>Tato transakce byla zrušena.</p>
                        <a [routerLink]="['/terminal']" routerLinkActive="router-link-active" class="btn btn-light"
                            i18n>Založit novou transakci</a>
                    </div>
                </div>
    
                <div *ngSwitchDefault class="col-12">
                    <div class="state text-center">
                        <i class="fa fa-loading"></i>
                        <p class="blue text-18"><b i18n>Potvrzování transakce</b></p>
                        <p class="text-14 aab" i18n>Jakmile zaznamenáme platbu, automaticky aktualizujeme stav.</p>
                    </div>
                </div>
            </div>
            <section>
                <hr class="w-100" />
                <div class="row">
                    <div class="col-12">
                        <h4 class="text-18 text-start fa-bold" i18n>Detailní přehled</h4>
                        <ul [attr.data-state]="detail.txState" class="tx-info-list">
                            <li class="info-list-item text-start">
                                <p class="text-14 m-0"><span i18n>Převádíme</span><span class="red fa-bold">
                                        {{detail.cryptoAmount}} {{detail.cryptoname}}</span></p>
                            </li>
                            <li class="info-list-item text-start">
                                <p class="text-14 m-0"><span>Na </span> <span class="blue bold">
                                        <span style="font-weight: bold"> {{detail.amount |
                                            currency:'CZK':'symbol'}}</span></span></p>
                                <p class="silver_blue text-14 m-0">
                                    <span><span i18n>Zpracování platby</span> <span>{{detail.payedDate |
                                            date:'medium'}}</span>, <span i18n>cílová adresa</span>: </span>
                                    <span>
                                        <!-- <a class="silver_blue" target="_blank" href="https://www.blockchain.com/cs/!@#/tx/23456"
                                            title="Detail transakce na Blockchainu"><span>{{detail.txid}}</span></a> -->
                                        <span>{{detail.txid}}</span>
                                    </span>
                                </p>
                                <p class="silver_blue text-14 m-0"><span i18n>Požadavek jsme přijali </span>
                                    <span>{{detail.created | date:'medium'}}</span>
                                </p>
                            </li>
                            <li class="info-list-item text-start">
                                <p class="text-14 m-0"><span i18n>Odesíláme na účet</span></p>
                            </li>
                            <li class="info-list-item text-start">
                                <p class="text-14 m-0"><span i18n>Zadáno v bance.</span></p>
                                <p class="silver_blue text-14 m-0"><span
                                        data-name="@Model.LastChanged">{{detail.bankImportedDate | date:'medium'}}</span>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr class="w-100" />
                <div class="row">
                    <div class="col-12">
                        <form #f="ngForm" [formGroup]="detailForm">
                            <section>
                                <mat-slide-toggle
                                    class="mat-primary"
                                    formControlName="sendEmails"
                                    (change)="useNotificationEmail(f)">
                                    <ng-container *ngIf="detailForm.controls.sendEmails.value === false; else ok">
                                        <span i18n>Zasílat oznámení o průběhu transakce</span>
                                      </ng-container>
                                      <ng-template #ok>
                                        <span i18n>Veškeré zprávy jsou odesílány na {{detailForm.controls.email.value}}</span>
                                      </ng-template>
                                </mat-slide-toggle>
                              </section>
                        </form>
                    </div>
                </div>
            </section>
        </section>
    </ng-container>
    
    <ng-template #merchant>
        <section id="detail">
            <div class="row align-items-center">
                <div class="col-12 col-md-6 text-center text-md-start">
                    <h2 class="m-0" i18n>Platíte obchodníkovi {{merchantInfo?.eshopName}}</h2>
                </div>
                <!-- <div class="col-12 col-md-6 text-center  text-md-end">
                    <span class="final-price">{{detail.amount | currency:'CZK':'symbol'}}</span>
                </div> -->
            </div>
            <hr class="w-100" />
            <div [ngSwitch]="detail.txState" class="row">
    
                <div *ngSwitchCase="'77'" class="txState col-12">
                    <div class="state text-center">
                        <mat-icon color="warn" class="text-44">cancel</mat-icon>
                        <p class="flex fa-bold blue text-18 mt-4"><b i18n>Vaší transakci jsme nemohli dokončit z důvodu
                                možného porušení našich podmínek.</b></p>
                        <p class="text-14 aab"><span i18n>Kontaktujte nás na</span><a
                                href="mailto:support@hdcrypto.cz">support&#64;hdcrypto.cz</a></p>
                    </div>
                </div>
    
                <div *ngSwitchCase="'8'" class="txState col-12">
                    <div class="state text-center">
                        <i class="fa fa-loading"></i>
                        <p class="flex fa-bold blue text-18 mt-4"><b i18n>Čekáme na potvrzení sítě</b></p>
                        <p class="text-14 aab"><span i18n>Trvá to jen chvíli v závislosti na vytížení sítě.</span>
                        </p>
                    </div>
                </div>
    
                <div *ngSwitchCase="'1'" class="txState col-12">
                    <div class="state text-center">
                        <mat-icon color="warn" class="text-44">cancel</mat-icon>
                        <p class="flex fa-bold blue text-18 mt-4"><b i18n>Zrušeno</b></p>
                        <p class="text-14 aab" i18n>Platbu jsme zrušili, protože vypršel časový limit.</p>
                    </div>
                </div>
    
                <div *ngSwitchCase="'2'" class="txState col-12">
                    <div class="state text-center">
                        <i class="fa fa-loading"></i>
                        <p class="text-success text-18"><b i18n>Zpracováváme vaší platbu</b></p>
                        <p class="text-14 aab">Zpracováváme vaší transakci. Pravděpodobně došlo k chybě, napište nám. Kód chyby #000002</p>
                        <!-- TODO: Formulář na help když nastane tento stav - dodat :-) -->
                    </div>
                </div>
    
                <div *ngSwitchCase="'3'" class="txState col-12">
                    <div class="state text-center">
                        <i class="fa fa-loading"></i>
                        <p class="text-success text-18"><b i18n>Čekáme na potvrzení sítě</b></p>
                        <p class="text-14 aab" i18n>Trvá to jen chvíli v závislosti na vytížení sítě.</p>
                    </div>
                </div>
    
                <div *ngSwitchCase="'4'" class="txState col-12">
                    <div class="state text-center">
                        <i class="fa fa-loading"></i>
                        <p class="text-success text-18"><b i18n>Čekáme na potvrzení sítě</b></p>
                        <p class="text-14 aab" i18n>Trvá to jen chvíli v závislosti na vytížení sítě.</p>
                    </div>
                </div>
    
                <div *ngSwitchCase="'5'" class="txState col-12">
                    <div class="state text-center">
                        <mat-icon class="success-icon">done</mat-icon>
                        <p class="text-success text-18"><b i18n>Zaplaceno</b></p>
                        <p class="text-14 aab" i18n>Váše platba proběhla v pořádku. Děkujeme za využití našich služeb.</p>
                        <a href="{{detail.merchantInfo?.eshopBackOfficeLink}}" class="btn btn-light" i18n>Zpět na {{detail.merchantInfo?.eshopName}}</a>
                    </div>
                </div>
    
                <div *ngSwitchCase="'6'" class="txState col-12">
                    <div class="state text-center">
                        <mat-icon class="success-icon">done</mat-icon>
                        <p class="text-success text-18"><b i18n>Zaplaceno</b></p>
                        <p class="text-14 aab" i18n>Vaše platba proběhla v pořádku. Děkujeme za využití našich služeb.</p>
                        <a href="{{detail.merchantInfo?.eshopBackOfficeLink}}" class="btn btn-light" i18n>Zpět na {{detail.merchantInfo?.eshopName}}</a>
                    </div>
                </div>
    
                <div *ngSwitchCase="'99'" class="txState col-12">
                    <div class="state text-center">
                        <mat-icon color="accent" class="text-44">sync_problem</mat-icon>
                        <p class="text-warn text-18"><b i18n>Storno</b></p>
                        <p class="text-14 aab" i18n>Tato transakce byla zrušena.</p>
                        <a [routerLink]="['/terminal']" routerLinkActive="router-link-active" class="btn btn-light"
                            i18n>Založit
                            novou transakci</a>
                    </div>
                </div>
    
                <div *ngSwitchDefault class="col-12">
                    <div class="state text-center">
                        <i class="fa fa-loading"></i>
                        <p class="blue text-18"><b i18n>Potvrzování transakce</b></p>
                        <p class="text-14 aab" i18n>Jakmile zaznamenáme platbu, automaticky aktualizujeme stav.</p>
                    </div>
                </div>
            </div>
            <section *ngIf="detail.txState !== 8">
                <hr class="w-100" />
                <div class="row">
                    <div class="col-12">
                        <h4 class="text-18 text-start fa-bold" i18n>Detaily</h4>
                        <ul [attr.data-state]="detail.txState" class="tx-info-list">
                            <li class="info-list-item text-start">
                                <p class="text-14 m-0"><span i18n>Posíláte </span><span class="red fa-bold">
                                        {{detail.cryptoAmount}} {{detail.cryptoname}}</span></p>
                            </li>
                            <li class="info-list-item text-start">
                                <p class="text-14 m-0"><span>Obchodníkovi zaplatíme</span> <span class="blue bold">
                                        <span style="font-weight: bold"> {{detail.amount |
                                            currency:'CZK':'symbol'}}</span></span></p>
                                <p class="silver_blue text-14 m-0">
                                    <span><span i18n>Zpracování platby</span> <span>{{detail.payedDate |
                                            date:'medium'}}</span>, <span i18n>cílová adresa</span>: </span>
                                    <span>
                                        <!-- <a class="silver_blue" target="_blank" href="https://www.blockchain.com/cs/!@#/tx/23456"
                                            title="Detail transakce na Blockchainu"><span>{{detail.txid}}</span></a> -->
                                        <span>{{detail.txid}}</span>
                                    </span>
                                </p>
                                <p class="silver_blue text-14 m-0"><span i18n>Požadavek byl vytvořen </span>
                                    <span>{{detail.created | date:'medium'}}</span>
                                </p>
                            </li>
                            <li class="info-list-item text-start">
                                <p class="text-14 m-0"><span i18n>Potvrzujeme platbu u obchodníka, počkejte prosím</span></p>
                            </li>
                            <li class="info-list-item text-start">
                                <p class="text-14 m-0"><span i18n>Platba dokončena </span></p>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr class="w-100" />
                <div class="row">
                    <div class="col-12">
                        <ng-container *ngIf="detail.email !== undefined && detail.email !== null; else noEmail">
                            <div class="text-start d-flex gap-2 flex-column">
                                <input class="form-control" placeholder="{{detail.email}}" value="{{detail.email}}"
                                    disabled />
                                <mat-hint i18n>Na tento e-mail vám budeme posílat veškeré informace.</mat-hint>
                            </div>
                        </ng-container>
                        <ng-template #noEmail>
                            <form #f="ngForm" [formGroup]="detailForm" (ngSubmit)="submitEmailForm(f)">
                                <div class="
                                d-flex 
                                flex-column 
                                flex-md-row 
                                align-items-md-baseline 
                                gap-3">
                                    <mat-form-field class="flex-fill">
                                        <mat-label i18n>Zadejte Váš e-mail</mat-label>
                                        <input matInput formControlName="email" placeholder="E-mail" />
                                    </mat-form-field>
                                    <button [disabled]="!detailForm.valid" role="button"
                                        type="submit" mat-raised-button i18n>Dejte mi vědět</button>
                                </div>
                            </form>
                        </ng-template>
                    </div>
                </div>
            </section>
        </section>
    </ng-template>
</ng-container>