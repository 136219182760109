<section id="progress">
    <ul class="text-center">
        <li class="state_1">
            <div class="progress-icon">
                <span>1.</span>
            </div>
            <div class="progress-text">
                <p i18n>Platební údaje</p>
            </div>
        </li>
        <li class="state_2">
            <div>
                <hr class="horizontal">
            </div>
        </li>
        <li class="state_2">
            <div class="progress-icon">
                <span>2.</span>
            </div>
            <div class="progress-text">
                <p i18n>Přijetí crypta</p>
            </div>
        </li>
        <li class="state_3">
            <div>
                <hr class="horizontal">
            </div>
        </li>
        <li class="state_3">
            <div class="progress-icon">
                <span>3.</span>
            </div>
            <div class="progress-text">
                <p i18n>Potvrzení sítě</p>
            </div>
        </li>
        <li class="state_4">
            <div>
                <hr class="horizontal">
            </div>
        </li>
        <li class="state_4">
            <div class="progress-icon">
                <span>4.</span>
            </div>
            <div class="progress-text">
                <p i18n>Hotovo</p>
            </div>
        </li>
    </ul>
</section>