import { Component, OnInit } from '@angular/core';
import { AppService, IAppServiceModel } from '../../services/component.service';
import {} from '@angular/localize';

@Component({
    templateUrl: './eshop.component.html',
    styleUrls: ['./eshop.component.scss']
})
export class EshopComponent implements OnInit {
    constructor(
        private _app: AppService
    ) {
        this._app.addTitle = {
            hideH1: true,
            pageMetaTitle: $localize`Obdržet platbu`,
            pageModel: $localize`Obdržet platbu`
        } as IAppServiceModel;
    }

    ngOnInit(): void {
    }
}

