<mat-card class="my-2" *ngFor="let item of files; let i=index">
                    
    <ng-container #files *ngIf="item.crossSizeLimit === false; else fileOverLimit">
        <mat-card-content>
            <img class="img-fluid" *ngIf="item.uploaded === false" mat-card-image [src]="getSafeUrl(item.url)"/>
            <p><mat-icon *ngIf="item.uploaded === true">done</mat-icon>{{item.name}}</p>
        </mat-card-content>
        <mat-card-actions *ngIf="item.uploaded === false">
            <button class="text-danger" mat-button i18n (click)="undo(item)">
                <mat-icon>close</mat-icon>
            </button>
            <!-- <button color="primary" mat-raised-button i18n (click)="upload(item)">
                <mat-icon>save_as</mat-icon>
            </button> -->
        </mat-card-actions>
    </ng-container>

    <ng-template #fileOverLimit>
        <p><mat-icon *ngIf="item.uploaded === true">done</mat-icon>Překročení limitu pro odeslání: {{item.name}}</p>
    </ng-template>

    <mat-card-footer>
        <!-- <mat-progress-bar *ngIf="item.progress" mode="determinate" value="{{item.progress}}"></mat-progress-bar> -->
    </mat-card-footer>
</mat-card>