<section class="row d-flex h-100c align-content-center justify-content-center gap-lg-5 tiles flex-column flex-md-row">
    <div class="col col-9 col-md-6 tile" data-tiletype="0">
        <div class="tile-body">
            <a [routerLink]="['/terminal']" routerLinkActive="router-link-active">
                <figure>
                    <img i18n-alt class="tile-image" alt="Zaplať kryptem" src="assets/images/qr-code-scan.jpg"/>
                    <figcaption class="bottom">
                        <h2 class="tile-title text-uppercase text-center" i18n>Zaplať kryptem</h2>
                        <p class="text-center">
                            <span class="text-info">
                                <small i18n>na libovolný český účet. Převod na koruny zařídíme my.</small>
                            </span>
                        </p>
                    </figcaption>
                </figure>
            </a>
        </div>
    </div>
    <div class="col col-9 col-md-6 tile" data-tiletype="1">
        <div class="tile-body">
            <a [routerLink]="['/eshop']" routerLinkActive="router-link-active" >
                <figure>
                    <img i18n-alt class="tile-image" alt="Přijímej krypto" src="assets/images/sendPayment_small.jpg">
                    <figcaption class="top">
                        <h2 class="tile-title text-uppercase text-center" i18n>Přijímej krypto</h2>
                        <p class="text-center">
                            <span class="text-info"><small i18n>bez kurzového rizika ve svém podnikání.</small></span>
                        </p>
                    </figcaption>
                </figure>
                
            </a>
        </div>
        
    </div>
</section>