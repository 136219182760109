import { Component, OnInit, Input } from '@angular/core';
import { CourseDtoOut, CourseWidget } from '../../models/course.model';

@Component({
    selector: 'widget-course',
    templateUrl: './courseWidget.component.html',
    styleUrls: ['./courseWidget.component.scss']
})
export class CourseWidgetComponent implements OnInit {

    @Input() public course: CourseWidget | null = null;

    constructor() { }

    ngOnInit(): void { }
}
