import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'terminal-progress',
    templateUrl: './progress.component.html',
    styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit {

    constructor() { }

    ngOnInit(): void { }
}
