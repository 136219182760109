import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[changeIcon]',
})
export class ChangeIconDirective {

    @Input() matIcon: string = null;
    @Input() matIconDefault: string = null;

    private _timer = null;

    constructor(private el: ElementRef){
    }

    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        this.AddIcon(this.matIconDefault);
    }

    @HostListener('click') onClick(){
        this.AddIcon(this.matIcon);
        this.Timer();
    }

    private AddIcon(icon:string){
        (<HTMLElement>this.el.nativeElement).querySelector("mat-icon").textContent = icon;
        
    }

    private Timer = () =>{
        this._timer = setTimeout(() => {
            this.AddIcon(this.matIconDefault);
        }, 1000);
    }

}