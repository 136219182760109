import { Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { CurrencyDtoOut } from '../../models/currency.model';
import { IDetailModel } from '../../models/detail.model';
import { IMenuItem } from '../../models/menuItem.model';
import { TerminalTransactionBroker } from '../../models/transaction.dto';
import { AzureService } from '../../services/azureServiceBus.services';
import { AppService, IAppServiceModel } from '../../services/component.service';
import { DetailChangeEventListener } from '../../services/detail.update';
import { RestService } from '../../services/rest.service';
import { Cultures } from '../../models/locales.model';

@Component({
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit, OnDestroy {
    
    public detail: IDetailModel = null;
    public loadingData: boolean = true;
    public lang: string = null;
    public langName: string = null;
    public footerCurrencies: CurrencyDtoOut[] = null;
    // TODO: interface where appear top bare under the form
    public txState: number = 0;

    @Input() menuItems:IMenuItem[] = [];

    private Subs: Subscription | null = null;
    private SubAzure: Subscription | null = null;
    private SubsStepper: Subscription | null = null;

    constructor(
        @Inject(LOCALE_ID) private _locale: string,
        private _app: AppService,
        private activeRoute: ActivatedRoute,
        private _rest: RestService,
        private _azure: AzureService,
        private _toast: MatSnackBar,
        private _router: Router,
        private _detail: DetailChangeEventListener
        ) {
            this.lang = this._locale;
            this.langName = Cultures.filter(a=>a.Abbreviation === this.lang)[0].CountryName;
            
            this.menuItems = Cultures;
            this._app.addTitle = {
                pageModel: $localize`Kryptoměnový terminál`,
                hideH1: true,
                pageMetaTitle: $localize`HD Crypto s.r.o | Kryptoměnový terminál`
            } as IAppServiceModel;
            this.SubsStepper = this._detail.actualStep.subscribe((_) => this.txState = _);
        }

    ngOnDestroy(): void {
        this.Subs?.unsubscribe();
        this.SubsStepper?.unsubscribe();
        this.SubAzure?.unsubscribe();
    }

    ngOnInit(): void {
        this.Subs = combineLatest([
            this._rest.GetCurrencies({
                inAmount: 20,
                inCurrency: 2,
                user: 1
            }),
            this.activeRoute.paramMap
        ]).subscribe({
            next: (_) =>{
                this.footerCurrencies = _[0];

                let txId = _[1].get("transactionHash");
                if(txId !== null){
                    this.getTransactionById(txId);
                }

            }
        });
    }

    getTransactionById = (txId: string) => {
        this._rest.GetTransactionByHash(txId).subscribe({
            next:(r) =>{
                // if any transaction comes try refresh view
                this.SubAzure = this._azure.Transactions.subscribe({
                    next: (r) => {
                        if(r !== null){
                            let tx:TerminalTransactionBroker = JSON.parse(r.body);
                            if(tx !== null && tx.data !== null){
                                this._rest.GetTransactionByHash(tx.data).subscribe(r=>{
                                    console.log(r);
                                    // If supose to be merchant transaction = Done state
                                    if(r.isMerchant){
                                        this.setStepper(6);
                                    } else{
                                        this.setStepper(r.status);
                                    }

                                    this.detail = {
                                            amount: r.youReceive,
                                            cryptoAmount: r.youSend,
                                            txState: r.status,
                                            txid:r.txiD_CW,
                                            fiatName: r.youReceiveCurrencyShortName,
                                            cryptoname: r.youSendCurrencyShortName,
                                            txId: r.transactionID,
                                            reverseTXID: r.transactionReverseID,
                                            created: new Date(r.createdDateTime),
                                            currencyUpdate: new Date(r.lastChanged),
                                            payedDate: r.transactionPayedDate,
                                            bankImportedDate: r.bankImportedDate,
                                            isMerchant: r.isMerchant,
                                            email : r.email
                                        } as IDetailModel;
                                });
                            }
                        }
                    },
                    error: (err) => console.log(err)
                });

                this._azure.JoinGroup(r.transactionID.toString());

                // consume transaction
                if(r === null) {
                    this._toast.open($localize`Transakce ${txId} nebyla nalezena`,$localize`Ok`, {
                        panelClass: "bg-danger"
                    });
                    throw new Error($localize`Transakce ${txId} nebyla nalezena`);
                } else{
                    this.loadingData = false;
                     // If supose to be merchant transaction = Done state
                     if(r.isMerchant){
                        this.setStepper(6);
                    } else{
                        this.setStepper(r.status);
                    }

                    this.detail = {
                        amount: r.youReceive,
                        cryptoAmount: r.youSend,
                        txState: r.status,
                        txid:r.txiD_CW,
                        fiatName: r.youReceiveCurrencyShortName,
                        cryptoname: r.youSendCurrencyShortName,
                        txId: r.transactionID,
                        reverseTXID: r.transactionReverseID,
                        created: new Date(r.createdDateTime),
                        currencyUpdate: new Date(r.lastChanged),
                        payedDate: r.transactionPayedDate,
                        bankImportedDate: r.bankImportedDate,
                        isMerchant: r.isMerchant,
                        email : r.email
                    } as IDetailModel;
                }
            }
        })
    }

    setStepper = (state:number) =>{
        if(state === 6){
            this._detail.setTopBarSteps(4);
        } else{
            this._detail.setTopBarSteps(3);
        }
    }


}
