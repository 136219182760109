import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { FilesPrototype, IFilesModel } from '../../../models/customFiles.model';

@Component({
  selector: 'app-file-result',
  templateUrl: './file-result.component.html',
  styleUrls: ['./file-result.component.scss']
})
export class FileResultComponent {
  
  @Input() public type: number = 0;
  @Input() public files: Array<IFilesModel> = [];

  @Output() public OnRemoved: EventEmitter<number> = new EventEmitter<number>();

  public fileLimit: number = 1;
  public fileTypeAvalible: string[] = [".jpg",".png",".jpeg"];


  constructor(private _sanitizer: DomSanitizer, private _toast: MatSnackBar) { }

  getSafeUrl = (url:string) => this._sanitizer.bypassSecurityTrustUrl(url);

  undo = (event: File) => {
    this.files = this.files.filter(m=> m != event);
    this.OnRemoved.emit(this.type);
  };

}
