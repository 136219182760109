<div>
    @if(getPageNumber(page) === "0"){
    <form #form="ngForm" [formGroup]="tForm" (ngSubmit)="Submited(form)">
        <div class="row gx-0 gx-md-2 justify-content-start">
            <mat-form-field class="col-12 col-md-6 flex-auto" appearance="outline">
                <mat-label i18n>Částka k odeslání</mat-label>
                <input i18n-placeholder formControlName="inAmount" matInput placeholder="např. 1 000" type="number">
            </mat-form-field>
            <ng-container *ngIf="fiatSelection != null">
                <mat-form-field class="col-12 col-md-4 flex-auto" appearance="outline">
                    <mat-label i18n>Vyberte měnu</mat-label>
                    <mat-select formControlName="inCurrency">
                        <mat-option [disabled]="true" i18n>Podporujeme</mat-option>
                        <mat-option *ngFor="let item of fiatSelection" value="{{item.currency?.currencyId}}">
                            ({{item.region?.currencySymbol}}) {{item.region?.currencyEnglishName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
        </div>
        <ng-container *ngIf="BankCodeIsValid === true; else plainBankCode">
            <div class="row gx-0 align-items-baseline gap-1">
                <mat-form-field class="col-3 col-md-2 flex-auto" appearance="outline">
                    <mat-label i18n>Předčíslí</mat-label>
                    <input readonly formControlName="bankPrefix" matInput placeholder="86" type="number">
                </mat-form-field>
                <mat-form-field class="col-4 col-md-5 flex-auto" appearance="outline">
                    <mat-label i18n>Číslo účtu</mat-label>
                    <input readonly formControlName="bankAccount" matInput placeholder="12345678" type="number">
                </mat-form-field>
                <mat-form-field class="col-4 flex-auto" appearance="outline">
                    <mat-label i18n>Kód banky</mat-label>
                    <mat-select formControlName="bankSuffix">
                        <mat-option *ngFor="let bankItem of BankCodes" [value]="bankItem.value"
                            [disabled]="bankItem.isDisabled">
                            <b>{{bankItem.value}}</b> - {{bankItem.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="row gx-0 mb-2">
                <button type="button" (click)="accNewEdit()" mat-raised-button>Zadat znovu</button>
            </div>
        </ng-container>
        <ng-template #plainBankCode>
            <div class="row gx-0 align-items-baseline">
                <mat-form-field class="col-12 col-md-12" appearance="outline">
                    <mat-label i18n>Číslo účtu</mat-label>
                    <input class="font-monospace text-16 text-center" formControlName="bankFullAccount" matInput
                        placeholder="xxxx-xxxxxxxxxx/xxxx" type="text">
                    <mat-hint class="text-center col-12">
                        <span i18n>Je podporována i IBAN forma pro CZ ( Českou republiku )</span>
                    </mat-hint>
                    <mat-error class="text-center col-12" *ngIf="tForm.controls['bankFullAccount'].invalid">
                        <span i18n>Toto číslo účtu není podporováno.</span>
                    </mat-error>
                </mat-form-field>
            </div>
        </ng-template>
        <div class="row gx-0">
            <mat-form-field class="col-12" appearance="outline">
                <mat-label i18n>Váš e-mail</mat-label>
                <input i18n-placeholder formControlName="email" matInput placeholder="vas@email.com" type="email">
            </mat-form-field>
        </div>
        <div class="row gx-0">
            <div class="col-12 text-start mb-1 px-0">
                <a href="#details" class="details-btn fa-bold" mat-button role="button" data-bs-toggle="collapse">
                    <span i18n>Volitelné informace</span>
                    <mat-icon>expand_more</mat-icon>
                </a>
            </div>
            <div id="details" class="collapse p-0">
                <div class="row gx-0 gx-md-2">
                    <mat-form-field class="col-12" appearance="outline">
                        <mat-label i18n>Zpráva pro příjemce</mat-label>
                        <textarea matInput formControlName="message" class="form-control" rows="5"
                            placeholder="Message"></textarea>
                    </mat-form-field>
                    <mat-form-field class="col-12 col-lg-6" appearance="outline">
                        <mat-label i18n>Variabilní symbol</mat-label>
                        <input pattern="([1-9]\d{0,9}$)" formControlName="vs" matInput type="text">
                    </mat-form-field>
                    <mat-form-field class="col-12 col-lg-6" appearance="outline">
                        <mat-label i18n>Specifický symbol</mat-label>
                        <input pattern="(\d{0,4}$)" formControlName="ss" matInput placeholder="3200" type="text">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-start my-5 px-0">
                <mat-checkbox formControlName="termsAndConditions" class="mx-1">
                    <a href="https://hdcrypto.cz/agreements" target="_blank" i18n>Souhlasím s podmínkami převodu</a>
                </mat-checkbox>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-12 text-start px-0">
                <button mat-raised-button color="primary" type="submit" aria-label="send-form" [disabled]="!tForm.valid"
                    i18n>
                    Pokračovat
                </button>
            </div>
        </div> -->
    </form>
    } @else if(getPageNumber(page) === "1"){
    <form #form_1="ngForm" [formGroup]="tForm">
        <div class="row align-items-center">
            <div class="col-12 col-md-6 text-center text-md-start">
                <h2 class="m-0" i18n>Odesíláte</h2>
            </div>
            <div class="col-12 col-md-6 text-center text-md-end">
                <ng-container *ngIf="showEditAmount === true; else showEditTemplate">
                    <mat-form-field [ngClass]="{'d-none': !_showEdit}" appearance="outline">
                        <mat-label i18n>Korekce částky</mat-label>
                        <input matInput type="number" (change)="showEdit(true)" formControlName="inAmount"
                            placeholder="{{tForm.value.inAmount}}" />
                    </mat-form-field>
                    <span [ngClass]="{'d-none': _showEdit}" class="final-price">{{tForm.value.inAmount |
                        currency:fiatSelection[tForm.controls['inCurrency'].value]?.currency.currencyCode:'symbol'}}</span>
                    <sup><mat-icon (click)="showEdit()" class="text-silver text-16"
                            style="cursor: pointer">edit</mat-icon></sup>
                </ng-container>
                <ng-template #showEditTemplate>
                    <span [ngClass]="{'d-none': _showEdit}" class="final-price">{{tForm.value.inAmount |
                        currency:fiatSelection[tForm.controls['inCurrency'].value]?.currency.currencyCode:'symbol'}}</span>
                </ng-template>
            </div>
        </div>
        <hr class="w-100" />
        <div class="row">
            <div class="col text-start">
                <h3 class="blue fa-bold" i18n>Čím si přejete platit?</h3>
            </div>
        </div>
        <!-- Dvojice měn-->
        <div class="row payment-buttons" *ngIf="selectionDouble !== null">
            <div class="col-12 mb-3 col-xl-6" *ngFor="let item of selectionDouble">
                <ng-container *ngIf="item.isEnable; else cantBePayedDueToAmount">
                    <button 
                        (click)="currencyClicked(item)" 
                        class="col-12 btn-selection" 
                        type="button"
                        mat-raised-button>
                        <div class="row justify-content-center align-items-center">
                            <div class="icon justify-content-center col-1 col-xl-2 d-none d-md-flex">
                                <span class="cc {{item.shortName}}"></span>
                            </div>
                            <div class="d-flex col-8">
                                <div i18n-title title="Zaplatit"
                                    class="d-flex flex-column btn-title w-100 align-items-start">
                                    <p class="fa-bold m-0 text-overflow text-start">{{item.inCryptoCurrencyName}}</p>
                                    <p class="m-0 text-silver"><small>{{item.cryptoAmountPromise}}
                                            {{item.shortName}}</small></p>
                                    <app-legal-decision [entryFormState]="item.entryFormState"></app-legal-decision>
                                </div>
                            </div>
                            <div class="d-flex col-2 justify-content-end">
                                <mat-icon class="hover-blue">arrow_forward_ios</mat-icon>
                            </div>
                        </div>
                    </button>
                </ng-container>
                <ng-template #cantBePayedDueToAmount>
                    <!-- Částka nemůže být zaplacena, protože měna má nastavenou hranici, kterou může disponovat.-->
                    <button 
                        class="col-12 btn-selection" 
                        type="button" 
                        mat-raised-button>
                        <div class="row justify-content-center align-items-center">
                            <div class="icon justify-content-center col-1 col-xl-2 d-none d-md-flex">
                                <span class="cc {{item.shortName}}"></span>
                            </div>
                            <div class="d-flex col-8">
                                <div class="d-flex flex-column btn-title w-100 align-items-start">
                                    <p class="fa-bold m-0 text-overflow text-start">{{item.inCryptoCurrencyName}}</p>
                                    <p class="m-0 text-silver"><small i18n>Částku nelze odeslat v této výši</small></p>
                                    <p class="badge-btn badge-icon badge-btn-danger"><mat-icon>close</mat-icon></p>
                                </div>
                            </div>
                            <div class="d-flex col-2 justify-content-end">
                                <mat-icon class="hover-blue">arrow_forward_ios</mat-icon>
                            </div>
                        </div>
                    </button>
                </ng-template>
            </div>
        </div>
        <!-- Měny separátně-->
        <div class="row payment-buttons mb-3" *ngFor="let item of selection">
            <div class="col-12">
                <ng-container *ngIf="item.isEnable; else cantBePayedDueToAmount">
                    <button (click)="currencyClicked(item)" class="col-12 btn-selection" type="button"
                        mat-raised-button>
                        <div class="row justify-content-center align-items-center">
                            <div class="icon d-none d-md-flex justify-content-center col-2 col-md-1">
                                <span class="cc {{item.shortName}}"></span>
                            </div>
                            <div class="d-flex col-8 col-xl-9">
                                <div i18n-title title="Zaplatit"
                                    class="d-flex flex-column btn-title w-100 align-items-start">
                                    <p class="fa-bold m-0 text-overflow text-start">{{item.inCryptoCurrencyName}}</p>
                                    <p class="m-0 text-silver"><small>{{item.cryptoAmountPromise}}
                                            {{item.shortName}}</small></p>
                                    <app-legal-decision [entryFormState]="item.entryFormState"></app-legal-decision>
                                </div>
                            </div>
                            <div class="d-flex col-2 justify-content-end">
                                <mat-icon class="hover-blue">arrow_forward_ios</mat-icon>
                            </div>
                        </div>
                    </button>
                </ng-container>
                <ng-template #cantBePayedDueToAmount>
                    <button class="col-12 btn-selection" type="button" mat-raised-button>
                        <div class="row justify-content-center align-items-center">
                            <div class="icon d-none d-md-flex justify-content-center col-2 col-md-1">
                                <span class="cc {{item.shortName}}"></span>
                            </div>
                            <div class="d-flex col-8 col-xl-9">
                                <div class="d-flex flex-column btn-title w-100 align-items-start">
                                    <p class="fa-bold m-0">{{item.inCryptoCurrencyName}}</p>
                                    <p class="m-0 text-silver"><small i18n>Částku nelze odeslat v této výši</small></p>
                                    <p class="badge-btn badge-icon badge-btn-danger"><mat-icon>close</mat-icon></p>
                                </div>
                            </div>
                            <div class="d-flex col-2 justify-content-end">
                                <mat-icon class="hover-blue">arrow_forward_ios</mat-icon>
                            </div>
                        </div>
                    </button>
                </ng-template>
            </div>
        </div>
        <div devOnly class="row text-start mt-4">
            <div class="col-12">
                <button mat-raised-button (click)="createMerchantTx()" color="basic" type="button"
                    aria-label="create-tx" i18n>
                    Vytvořit jako Alza.cz
                </button>
                <p class="pt-1">
                    <mat-hint>*BTC ~ {{fiatSelection[tForm.controls['inCurrency'].value]?.currency.currencyCode}}</mat-hint>
                </p>
            </div>
        </div>
    </form>
    } @else if(getPageNumber(page) ==="2") {
        <ng-container *ngIf="_finalCourse !== null; else showLastPage">
            <form [formGroup]="tForm">
                <div class="row align-items-center">
                    <div class="col-12 col-md-6 text-center text-md-start">
                        <ng-container *ngIf="merchantInfo === null; else merchantTitle">
                            <h2 class="m-0" i18n>Na účet odešleme</h2>
                        </ng-container>
                        <ng-template #merchantTitle>
                            <h2 class="m-0" i18n>Platíte u obchodníka {{merchantInfo?.eshopName}}</h2>
                        </ng-template>
                    </div>
                    <div class="col-12 col-md-6 text-center  text-md-end">
                        <span class="final-price">{{tForm.value.inAmount |
                            currency:fiatSelection[tForm.controls['inCurrency'].value]?.currency.currencyCode:'symbol'}}</span>
                    </div>
                </div>
                <hr class="w-100" />
                <div class="row">
                    <div class="qr-payment col-12 col-md-5 mw-200">
                        <figure class="qr-wrapper">
                            <ng-container *ngIf="_finalCourse?.qrCodeImage !== null; else elseTemplate">
                                <img style="max-height: 100%;max-width:100%" i18n-alt i18n-title
                                    src="{{_finalCourse?.qrCodeImage}}" alt="Vygenerovaný QR pro Vaši platbu"
                                    title="Vygenerovaný QR pro Vaši platbu" />
                            </ng-container>
                            <ng-template #elseTemplate>
                                <ng-container *ngIf="_txHasStorno==false; else stornoThen">
                                    <span i18n>QR kód se nahrává...</span>
                                </ng-container>
                                <ng-template #stornoThen>
                                    <span i18n>Stornováno</span>
                                </ng-template>
                            </ng-template>
                        </figure>
                    </div>
                    <div class="flex-auto details col-12 col-md-7 text-start">
                        <p><span class="big fa-bold" i18n>Převeďte</span> <a (click)="stopProp($event)"
                                [cdkCopyToClipboard]="_finalCourse?.inCryptoAmount" changeIcon matIcon="check"
                                matIconDefault="content_copy"><span class="big fa-bold blue copy underline-dotted">
                                    {{_finalCourse?.inCryptoAmount}} {{_finalCourse?.shortName}}
                                    <mat-icon></mat-icon></span></a></p>
                        <p class="text-silver-blue"><span i18n>na</span> {{_finalCourse?.currencyType}} <span
                                i18n>adresu</span></p>
                        <p class="big fa-bold blue"><a (click)="stopProp($event)" changeIcon matIcon="check"
                                matIconDefault="content_copy"><span style="overflow: auto;max-width: 100%;" class="d-block"
                                    [cdkCopyToClipboard]="_finalCourse?.address">{{_finalCourse?.address}}
                                    <mat-icon></mat-icon></span></a></p>
                        <button mat-stroked-button class="py-4" (click)="openInWallet()">
                            <div class="d-flex justify-content-center align-items-center">
                                <span class="cc {{_finalCourse?.currencyType}}"></span>
                                <span class="fa-bold" i18n>Uhradit z peněženky</span>
                            </div>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <p class="text-silver-blue text-start" i18n>Nedoporučujeme převádět prostředky z investičních služeb
                            (např. Binace, Bittrex, Kraken atd.), protože odesílají transakci s časovým odstupem.</p>
                    </div>
                </div>
            </form>
        </ng-container>
        <ng-template #showLastPage>
            <div *ngIf="page === 2" class="d-flex justify-content-center">
                <p i18n>Připravuji detail platby...</p>
            </div>
        </ng-template>
    } @else{
        <div class="d-flex justify-content-center">
            <mat-spinner></mat-spinner>
        </div>
    }

    <!-- 
        Progress
    -->
    <div id="progress-line" class="row mb-2 mt-5" [ngClass]="{'d-none': !progressBar.show}">
        <div class="col-12">
            <div class="d-flex justify-content-between">
                <p class="text-silver-blue">
                    Na odeslání máte ještě <span class="blue"><b>{{progressBar.time | date:'mm'}}</b> minut
                        <b>{{progressBar.time | date:'ss'}}</b> sekund</span>
                </p>
                <p class="text-silver-blue">
                    <ng-container *ngIf="merchantInfo === null;else merchantLink">
                        <a href="#" (click)="cancelTransaction($event)" i18n>Zrušit</a>
                    </ng-container>
                    <ng-template #merchantLink>
                        <a href="{{merchantInfo?.eshopBackOffice}}" i18n>Zpět do obchodu
                            ({{merchantInfo?.eshopName}})</a>
                    </ng-template>
                </p>
            </div>
        </div>
        <div class="col-12">
            <mat-progress-bar [color]="progressBarColor" mode="determinate"
                value="{{progressBar.percent}}"></mat-progress-bar>
        </div>
    </div>
</div>