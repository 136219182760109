import { Component, OnInit } from '@angular/core';
import { AppService, IAppServiceModel } from '../../services/component.service';

@Component({
    templateUrl: './notfound.component.html',
    styleUrls: ['./notfound.component.scss']
})
export class NotFoundComponent implements OnInit {
    constructor(private _app: AppService) {
        this._app.addTitle = {
            pageModel: $localize`Váš požadavek skončil chybou.`,
            hideH1: false
        } as IAppServiceModel;
    }

    ngOnInit(): void { }
}
