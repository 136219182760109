import { Directive, ElementRef, EventEmitter, HostListener, Output, SimpleChanges } from '@angular/core';
import { OutputFileType } from 'typescript';

@Directive({
    selector: '[changeClass]',
})
export class ChangeClassDirective {

    @Output() getClass:EventEmitter<ElementRef> = new EventEmitter<ElementRef>(this.el.nativeElement);

    constructor(private el: ElementRef){}

    ngOnChanges(changes: SimpleChanges): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class.
        console.log(changes);
    }
    
}