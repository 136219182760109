import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './qrscan.component.html',
    styleUrls: ['./qrscan.component.scss']
})
export class QrScanComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
