import { Injectable, Input } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { MerchantModel } from "../models/merchant.model";
import { TransactionDetail } from "../models/transaction.dto";
import { RestService } from "./rest.service";

@Injectable({
    providedIn: "root"
}) export class MerchantService{

    @Input() public page: number = 0;

    private merchantBehave = new BehaviorSubject<MerchantModel | null>(null);
    public isMerchant = this.merchantBehave.asObservable();

    constructor(
        private _route: Router,
        private _rest: RestService,
        private _toast: MatSnackBar
        ){}

    public VisitedByMerchant(txId: string){
        this._rest.GetTransactionByHash(txId).toPromise()
        .catch(err => {
            this._toast.open($localize`Tato transakce nebyla nalezena. Můžete vyplnit formulář a vytvořit novou transakci, případně nás kontaktujte.`, null,{
             panelClass: 'bg-danger',
             duration: 2000
            });
            
         }).then((r:TransactionDetail) =>{
             if(r !== undefined && r.status === 2){
                 this.merchantBehave.next({
                     isMerchant: true,
                     transactionId: txId,
                     transaction: r
                 } as MerchantModel);
             } else{
 
                 if(r !== undefined){
                    //  this._toast.open($localize`Transakce ve špatném stavu. Prosím kontaktujte nás na support@hdcrypto.cz.`, 'OK', {
                    //      panelClass: 'bg-danger'
                    //  });
                    this._route.navigate(["/invoice",txId], {
                        replaceUrl: true
                    })
                 }
              }
         });
    }

}