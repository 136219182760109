import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { HubConnection } from "@microsoft/signalr";
import { BehaviorSubject } from "rxjs";
import {environment} from "../environments/environment";
import { MessagesHub } from "./hub/messagess.hub";

@Injectable({
    providedIn: "root"
}) export class AzureService {

    private _transactionEvent = new BehaviorSubject<MessagesHub>(null);
    public Transactions = this._transactionEvent.asObservable();

    private _client: HubConnection = null;

    constructor(){}

    public Connect = () =>{
        this._client = new signalR.HubConnectionBuilder()
        .withUrl(`${environment.signalR.host}/${environment.signalR.hubName}`)
        .withAutomaticReconnect([5000, 10000, 20000, 30000, 60000])
        .build();

       this._client.start().then(() => this.LogMessagess()).catch(err => console.error(err));

       this._client.onreconnected((id) => console.log('Reconnected: ' + id));

    }

    // Join to specific group
    public JoinGroup = (groupName: string) =>{
        console.log(`Connecting to TX: ${groupName}`);

        if(this._client == null){
            console.log('Trying connect to client.');
            this.Connect();
        }
    }

    private LogMessagess = () =>{
        this._client.on("SendBusMessage",(data: MessagesHub) =>{
            this._transactionEvent.next(data);
        });
        this._client.on("SendText",(msg) =>console.info(msg));
    }
}