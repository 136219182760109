export interface IFilesModel extends File{
    url?:string;
    progress?:number;
    uploaded?:boolean;
    sizeInMb?:number;
    crossSizeLimit?:boolean;
}

export class FilesPrototype {
    private _file: IFilesModel;

    get File():IFilesModel{
        return this._file;
    }

    constructor(File: IFilesModel, limit: number){
        this._file = File;
        this._file.url = URL.createObjectURL(File);
        this._file.progress = 1;
        this._file.uploaded = false;
        this._file.sizeInMb = (File.size / 1000000);
        this._file.crossSizeLimit = this._file.sizeInMb > limit;
    }
}