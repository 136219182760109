import { Injectable, Input, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable(
    {
        providedIn: "root"
    }
)
export class AppService {

    private title = new BehaviorSubject<IAppServiceModel>(null);
    public getTitle = this.title.asObservable();

    set addTitle(v: IAppServiceModel) {
        this.title.next(v);
    }

}

export class IAppServiceModel{
    pageModel?: string;
    pageMetaTitle?: string;
    hideH1?: boolean;
}