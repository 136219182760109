import { IEnvironment, ISignalRHub } from "../models/environment.model";

export const environment:IEnvironment = {
  production: true,
  security:{
    privateKey: "kn3f08eTXggrjw1P06c8rmTPdTCLVoAIXrT9XY6l",
    identity:{
      clientId: "3B92CC62-3BAB-4907-AA4E-7A81EF83102C_v0",
      scope: "client customer",
      useRefreshTokens: true,
      domain: "https://identity.hdcrypto.cz"
    } 
  },
  signalR:{
    host: "https://zaplatkryptem.cz",
    hubName:"message-broker"
  } as ISignalRHub
};
