import { DOCUMENT, Location } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialog, MatDialogState } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { DialogContentComponent } from '../../components/enterInfoModal/dialog-content.component';
import { CourseWidget } from '../../models/course.model';
import { CurrencyDtoIn, CurrencyDtoOut } from '../../models/currency.model';
import { IMenuItem } from '../../models/menuItem.model';
import { ProgressModel } from '../../models/progress.model';
import { AppService, IAppServiceModel } from '../../services/component.service';
import { CounterService } from '../../services/counter.service';
import { CourseWidgetService } from '../../services/courseWidget.service';
import { DetailChangeEventListener } from '../../services/detail.update';
import { MerchantService } from '../../services/merchant.service';
import { RestService } from '../../services/rest.service';
import { Cultures } from '../../models/locales.model';

@Component({
    templateUrl: './terminal.component.html',
    styleUrls: ['./terminal.component.scss']
})
export class TerminalComponent implements OnInit, OnDestroy {
    private filledFormEmiter: UntypedFormArray = new UntypedFormArray([]);
    public lang: string = "";
    public langName: string = "";
    public page: number = 100;
    private Subs: Subscription;
    public txState: number = 0;
    public show: boolean = true;
    public progressBar: ProgressModel = new ProgressModel();
    public progressBarColor: ThemePalette = "primary";
    public footerCurrencies: CurrencyDtoOut[] = null;
    public countedCourse: CourseWidget = {
        cryptoAmount: 0.2,
        currecny: "CZK"
    } as CourseWidget;

    @Input() menuItems: IMenuItem[] = [];

    constructor(
        private _app: AppService,
        @Inject(DOCUMENT) private _document: Document, 
        @Inject(LOCALE_ID) public _localInfo: string,
        private _toast: MatSnackBar,
        private _timer: CounterService,
        private _rest: RestService,
        private _course: CourseWidgetService,
        private _rout: ActivatedRoute,
        private _merchant: MerchantService,
        private _detail: DetailChangeEventListener,
        public _dialog: MatDialog
    ) {
        this.menuItems = Cultures;
        this.lang = this._localInfo;
        this.langName = Cultures.filter(a=>a.Abbreviation === this.lang)[0].CountryName;
        
        this._app.addTitle = {
            pageModel: $localize`Kryptoměnový terminál`,
            hideH1: true,
            pageMetaTitle: $localize`Cryptopay | Kryptoměnový terminál`
        } as IAppServiceModel;
    }

    ngOnDestroy(): void {
        this._timer?.Refresh();
        this.Subs?.unsubscribe();
    }

    ngOnInit(): void {

        this._rout.paramMap.subscribe((r) => {

            const txNumber = r.get("transactionHash");

            if (txNumber !== null && txNumber !== undefined && txNumber !== "") {
                this._merchant.VisitedByMerchant(txNumber);
            } else{
                this.page = 0;
                if(this._dialog.openDialogs.filter(a=>a.id == 'enterInfoModal')[0]?.getState() != MatDialogState.OPEN){
                    this._dialog.open(DialogContentComponent,{
                        width: '500px',
                        height: 'auto',
                        id: 'enterInfoModal',
                        data: {
                          Title: $localize`Vážení uživatelé služby Cryptopay`,
                          Message: $localize`<p>
Službu provozujeme s minimálními poplatky, které jsou často nižší, než cena za provoz serverů. Vyvažuje to radost, že naši jednoduchou službu používáte a vkládáte do nás svou důvěru.
<br><br>
<b>V tuto chvíli bohužel musíme pozastavit náš provoz.</b>
<br><br>
Poslední roky jsme vyšetřovaní ze strany ČNB. Důvodem je, že pokud od vás vykoupíme vaše kryptoměny, nesmíme vám peníze za tento obchod poslat převodem. Celkem zvláštní věc, že? Nemáme bohužel finanční možnosti vést dlouhé a nákladné právní spory s regulátorem trhu, který se podle nás snaží tento moderní segment co nejvíce omezit a znepříjemnit normálním lidem.
<br><br>
S tímto přístupem budeme i nadále pouze montovnou a chytré mozky budou technologické startupy zakládat i nadále kdekoliv jinde.
</p>
<p>„Váš tým Cryptopay“</p>`
                        }
                      });
                }
            }
        });

        this.Subs = combineLatest([
            this._course.courseUpdated,
            this._rest.GetCurrencies({
                inAmount: 1,
                inCurrency: 0
            } as CurrencyDtoIn),
            this._timer.WatchTime,
            this._detail.actualStep
        ]).subscribe({
            next: (_) => {
                /**
                 * Updating course
                 */
                this.countedCourse = _[0];

                /**
                 *  Get all currencies
                 */
                this.footerCurrencies = _[1];

                /**
                 * Progress line model
                 */
                this.progressBar = {
                    show: _[2].show,
                    percent: _[2].percent,
                    time: new Date(_[2].time),
                    storno: _[2].storno
                } as ProgressModel;

                if (this.progressBar.storno) {
                    this.progressBarColor = "warn";
                    this.progressBar.percent = 100;
                } else {
                    this.progressBarColor = "primary";
                }

                this.txState = _[3];
            }
        });
    }

    validateKey = (key, arr: Array<string>) => arr.find(key);

    onFormChanged = (form: UntypedFormArray) => this.filledFormEmiter = form;

    //#region Testing helpers
    
    // Testing progress bar countdown
    startTimer = () => this._timer.Start();
    //#endregion
}
