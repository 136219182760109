import { Directive, ElementRef, HostListener } from "@angular/core";
import { environment } from "../environments/environment";

@Directive(
    {
        selector: '[devOnly]'
    }
) export class DevOnlyDirective {

    constructor(private el: ElementRef) { }

    ngOnInit(): void {
        if(environment.production){
            this.el.nativeElement.remove();
        }
    }

}