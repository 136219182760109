import { Component, Input, NgZone, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, NgForm, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subscription } from "rxjs";
import { IDetailModel } from "../../models/detail.model";
import { TerminalTransactionEmailDtoIn } from "../../models/transaction.dto";
import { RestService } from "../../services/rest.service";

@Component({
    selector: 'detail-form',
    templateUrl: 'detailForm.component.html',
    styleUrls:['detailForm.component.scss']
})
export class DetailFormComponent implements OnInit, OnDestroy {
    
    public loadingData: boolean = true;

    @Input() public detail: IDetailModel | null = null;
    private SubDetail:Subscription | null = null;

    @Input() public detailEmailDefault: String | null = null;
    public detailForm: UntypedFormGroup = new UntypedFormGroup({
        email: new UntypedFormControl(null, {
            validators: [
                Validators.email,
                Validators.required
            ]
        }),
        sendEmails: new UntypedFormControl(false)
    });
    
    constructor(
        private _rest: RestService,
        private _toast: MatSnackBar
        ) { }
        
    ngOnDestroy(): void {
        this.SubDetail?.unsubscribe();   
    }

    ngOnInit() {
        if(this.detail!=null){
            this.detailForm.controls["sendEmails"].setValue(this.detail.email !==null);
            this.detailForm.controls["email"].setValue(this.detail.email);
        }
    }

    public submitEmailForm = (f:NgForm) =>{
        this._toast.open($localize`Ukládám Váš email ${f.form.controls['email'].value} k transakci ${this.detail?.reverseTXID}`);
        this._rest.PostTransactionEmail({
            email: f.form.controls['email'].value,
            transactionid: this.detail?.txId ?? null
        }).toPromise().catch(r => console.log(r)).then((m:TerminalTransactionEmailDtoIn | void)=> {
            
            if(typeof m ==="object"){
                this.detail !== null? this.detail.email = m.email : null;

                this._toast.open($localize`Uložili jsme Vám email k transakci ${this.detail?.reverseTXID}`, 'OK', {
                    panelClass:'bg-success'
                });
            }
            
        });
    }

    public useNotificationEmail = (f:NgForm) =>{
        this.detailForm.controls["email"].setValue("...");
        this._toast.open($localize`Ukládám upozornění k transakci ${this.detail?.reverseTXID}`);
        this._rest.PostTransactionNotificationEmailClone({
            value: f.form.controls['sendEmails'].value,
            transactionid: this.detail?.txId
        }).toPromise().catch(r => console.log(r)).then((m:String | void)=> {
            if(typeof m === "object"){
                this._toast.open($localize`Uložili jsme Vaši volbu k transakci ${this.detail?.reverseTXID}`, 'OK', {
                    panelClass:'bg-success'
                });
                this.detailForm.controls["email"].setValue(m);
            }
        });
    }
}