import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotFoundComponent } from './notfound/notfound.component';
import { TerminalComponent } from './terminal/terminal.component';
import { AdminComponent } from './admin/admin.component';
import { EshopComponent } from './eshop/eshop.component';
import { QrScanComponent } from './qrscan/qrscan.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { LegalComponent } from '../components/legalComponent/legal.component';
const routes: Routes = [
    {
        path: "",
        component: TerminalComponent,
    },
    {
        path: ":transactionHash",
        component: TerminalComponent
    },
    {
        path: "admin",
        component: AdminComponent
    },
    {
        path: "eshop",
        component: EshopComponent
    },
    {
        path: "qr-code/:transactionHash",
        component: TerminalComponent
    },
    {
        path:"qr-scan",
        component: QrScanComponent
    },
    {
        path: "legal",
        component: LegalComponent,
        children: [
            {
                path:":userId",
                component: LegalComponent
            }
        ]
    },
    {
        path:"invoice/:transactionHash",
        component: InvoiceComponent
    },
    { 
        path: "**",
        component: NotFoundComponent 
    }

    //{ path: 'path/:routeParam', component: MyComponent },
    //{ path: 'staticPath', component: ... },
    //{ path: '**', component: ... },
    //{ path: 'oldPath', redirectTo: '/staticPath' },
    //{ path: ..., component: ..., data: { message: 'Custom' }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule {}
