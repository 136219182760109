import { Directive, forwardRef, inject, Inject, Injectable, LOCALE_ID } from "@angular/core";
import { AbstractControl, AsyncValidator, FormArray, NG_ASYNC_VALIDATORS, NG_VALIDATORS, ValidationErrors, ValidatorFn } from "@angular/forms";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { BankAccountDtoOut, IBanksInfo } from "../models/banks.model";
import { RestService } from "../services/rest.service";

export function validateBankAccount() : ValidatorFn{
    return (control: AbstractControl ) : object | null =>{
        return {value: "IBAN není podporován"};
    }
}

@Injectable({
    providedIn: "root"
}) export class ValidateBankAccountInject implements AsyncValidator{

    private behave = new BehaviorSubject<BankAccountDtoOut>(null);
    public BankAccountValidResult = this.behave.asObservable();

    constructor(private _rest: RestService, @Inject(LOCALE_ID) private _local: string){}

    validate(control: AbstractControl<any, any>): Promise<ValidationErrors> | Observable<ValidationErrors> {
        return this._rest.GetBankAccountInfo({
            bankNumber: control.value,
            culture: this._local
            }).pipe(map(m=>{
                this.behave.next(m.value);
                return m.value.isValid == true ? null : ({"value" : "chyba"});
            }),catchError(err => of(err)));
    }
    
}


