
<article>
    <div class="row justify-content-md-center">
        <div class="col col-12 col-md-8">
            <h2 i18n>Odesílání plateb skrze internetový e-shop</h2>
            <p i18n>Služba je velmi jednoduchá, postačí ve vašem backoffice daného e-shopu sestavit okaz k nám na stránku, které v podstatě e-shop předá parametry pomocí URL. Jakmile se přes tyto parametry zákazník dostane na naši stránku, vše se automaticky spáruje a zákazník může ihned provést platbu. Objednávka bude naším systémem zaplacena dle vložených parametrů.</p>
            <dl>
                <dt i18n>Krok 1.</dt>
                <dd><img i18n-alt class="img-fluid" alt="Vložte do košíku zboží, které chcete proplatit kryptoměnou" src="assets/images/eshop/step1.png"/></dd>

                <dt i18n>Krok 2.</dt>
                <dd><img i18n-alt class="img-fluid" alt="Vytvořte jednoduchý request na naši sluźbu pomocí GET methody a jednotlivými parametry." src="assets/images/eshop/step4.png"/></dd>

                <dt i18n>Krok 3.</dt>
                <dd><img i18n-alt class="img-fluid" alt="Zaplaťte zakázku, platbu obratem odešleme na Váš účet." src="assets/images/eshop/step3.png"/></dd>
            </dl>
        </div>
        
    </div>
</article>